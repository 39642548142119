// @flow strict
import React from 'react';
import Img from "gatsby-image"
import { Link } from 'gatsby';
import Author from './Author';
import Comments from './Comments';
import Content from './Content';
import Meta from './Meta';
import Tags from './Tags';
import styles from './Post.module.scss';
import type { Node } from '../../types';
import AdSense from 'react-adsense'
import RelatedArticles from '../RelatedPosts'

type Props = {
  post: Node
};


// const Post = ({ post }) => {
//   let featuredImage
//   if (post.frontmatter.featuredImage) {
//     featuredImage = (
//       <Img fluid={post.frontmatter.featuredImage.childImageSharp.fluid} />
//     )
//   } else {
//     featuredImage = <hr />
//   }
//
//   return (
//     <div className="content">
//       <header>
//         <h1 className="is-marginless">{post.frontmatter.title}</h1>
//         <p className="is-marginless has-text-right">{post.frontmatter.date}</p>
//         {featuredImage}
//       </header>
//       <div dangerouslySetInnerHTML={{ __html: post.html }} />
//     </div>
//   )
// }




const Post = ({ post }: Props) => {
  const { html } = post;
  const { categorySlug, tagSlugs, slug } = post.fields;
  const { category, tags, title, date } = post.frontmatter;

  return (
    <div className={styles['post']}>
      <div className={styles['post__content']}>
         <p>&nbsp;</p>
        <Content body={html} title={title} date={date}/>
      </div>

      <div className={styles['post__footer']}>
        {/* <Link to={categorySlug} className={styles['post__content']}>{category}</Link> */}
        {tags && tagSlugs && <Tags tags={tags} tagSlugs={tagSlugs} />}


      {/* <p>&nbsp;</p>
        <AdSense.Google
        client="ca-pub-3054056161900944"
        slot="7568612720"
        format="rectangle"
        />
        <p>&nbsp;</p>
        <Author /> */}
      </div>

      {/* <div className={styles['post__comments']}>
        <Comments postSlug={slug} postTitle={post.frontmatter.title} />
      </div> */}
    </div>
  );
};


// const Post = ({ post }: Props) => {
//   const { html } = post;
//   const { tagSlugs, slug } = post.fields;
//   const { tags, title, date } = post.frontmatter;
//
//   return (
//     <div className={styles['post']}>
//       <Link className={styles['post__home-button']} to="/">All Articles</Link>
//
//       <div className={styles['post__content']}>
//         <Content body={html} title={title} date={date}/>
//       </div>
//
//       <div className={styles['post__footer']}>
//         <Meta date={date} />
//         {tags && tagSlugs && <Tags tags={tags} tagSlugs={tagSlugs} />}
//         <Author />
//       </div>
//
//       <div className={styles['post__comments']}>
//         <Comments postSlug={slug} postTitle={post.frontmatter.title} />
//       </div>
//     </div>
//   );
// };

export default Post;
