// @flow strict
import React from 'react';
import styles from './Content.module.scss';
import moment from 'moment';

// type Props = {
//   body: string,
//   title: string,
// };
//
// const Content = ({ body, title }: Props) => (
//   <div className={styles['content']}>
//     <h1 className={styles['content__title']}>{title}</h1>
//     <div className={styles['content__body']} dangerouslySetInnerHTML={{ __html: body }} />
//   </div>
// );

type Props = {
  body: string,
  title: string,
  date: string
};

const Content = ({ body, title, date }: Props) => (
  <div className={styles['content']}>
    <h1 className={styles['content__title']}>{title}</h1>
    <p className={styles['content__date']}>{moment(date).format('YYYY-MM-DD')}</p>
    <div className={styles['content__body']} dangerouslySetInnerHTML={{ __html: body }} />
  </div>
);

export default Content;
